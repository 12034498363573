<template>
<div class="cabinet__content autoOrder">
    <!-- <div class="cabinet__headerMob d-md-none">
        <router-link to="/"></router-link>Автозаказ
    </div> -->
    <div>
        <h1 class="cabinet__header mb-3 d-none d-md-block">Автозаказ</h1>
        <div class="row">
            <div class="col-md-12 autoOrderItem">
                <h2 class="autoOrderItem__header">День</h2>
                <div class="autoOrder-inputs autoOrder-inputs-days">
                    <div v-for="(item, index) in listDates" :key="index">
                        <button class="btnSelectDay" :class="{ active: item.is_active }" @click="selectDate(index, item.date)">
                            {{$moment(item.date).format('ddd, DD.MM')}}
                        </button>
                    </div>
                    <VueDatePicker v-model="selectedDate" no-header :allowed-dates="allowedDates" no-calendar-icon format="dd, DD.MM" @onChange="showConsoleMessage('-- date changed --')">
                        <template #activator="{ activ }">
                            <button class="autoOrder-inputs__add-day" ref="activator" type="button">
                                Добавить день
                                {{ activ }}
                            </button>
                        </template>
                    </VueDatePicker>
                    <!-- <button type="text" class="autoOrder-inputs__add-day" @click="add">Добавить день</button> -->
                </div>
            </div>
            <div class="col-md-12 autoOrderItem">
                <h2 class="autoOrderItem__header pt-4 mt-1">Тип блюда</h2>
                <div class="autoOrder-inputs">
                    <div class="inputs-line pb-1" v-show="currentDate ==$store.state.cabinet.autoorder.available_dates[0].date">
                        <input type="checkbox" id="1a" name="" value="8:00 - 8:30" />
                        <label for="1a" @click="selectTypeDish(typeDish.times.breakfast.id)">{{ typeDish.times.breakfast.type }}</label>
                      
                        <input type="checkbox" id="3c" name="" value="orange" />
                        <label for="3c" @click="selectTypeDish(typeDish.times.dinner.id)">{{ typeDish.times.dinner.type }}</label>
                        <input type="checkbox" id="2b" name="" value="8:00 - 10:59" />
                        <label for="2b" @click="selectTypeDish(typeDish.times.lunch.id)">{{ typeDish.times.lunch.type }}</label>
                    </div>
                    <div class="inputs-line pb-1" v-show="currentDate ==$store.state.cabinet.autoorder.available_dates[1].date">
                        <input type="checkbox" id="11a" name="" value="8:00 - 8:30" />
                        <label for="11a" @click="selectTypeDish(typeDish.times.breakfast.id)">{{ typeDish.times.breakfast.type }}</label>
                       
                        <input type="checkbox" id="33c" name="" value="orange" />
                        <label for="33c" @click="selectTypeDish(typeDish.times.dinner.id)">{{ typeDish.times.dinner.type }}</label>
                        <input type="checkbox" id="22b" name="" value="8:00 - 10:59" />
                        <label for="22b" @click="selectTypeDish(typeDish.times.lunch.id)">{{ typeDish.times.lunch.type }}</label>
                    </div>
                    <div class="inputs-line pb-1" v-show="currentDate ==$store.state.cabinet.autoorder.available_dates[2].date">
                        <input type="checkbox" id="111a" name="" value="8:00 - 8:30" />
                        <label for="111a" @click="selectTypeDish(typeDish.times.breakfast.id)">{{ typeDish.times.breakfast.type }}</label>
                       
                        <input type="checkbox" id="333c" name="" value="orange" />
                        <label for="333c" @click="selectTypeDish(typeDish.times.dinner.id)">{{ typeDish.times.dinner.type }}</label>
                        <input type="checkbox" id="222b" name="" value="8:00 - 10:59" />
                        <label for="222b" @click="selectTypeDish(typeDish.times.lunch.id)">{{ typeDish.times.lunch.type }}</label>
                    </div>
                    <div class="inputs-line pb-1" v-show="currentDate ==$store.state.cabinet.autoorder.available_dates[3].date">
                        <input type="checkbox" id="1111a" name="" value="8:00 - 8:30" />
                        <label for="1111a" @click="selectTypeDish(typeDish.times.breakfast.id)">{{ typeDish.times.breakfast.type }}</label>
                       
                        <input type="checkbox" id="3333c" name="" value="orange" />
                        <label for="3333c" @click="selectTypeDish(typeDish.times.dinner.id)">{{ typeDish.times.dinner.type }}</label>
                        <input type="checkbox" id="2221b" name="" value="8:00 - 10:59" />
                        <label for="2221b" @click="selectTypeDish(typeDish.times.lunch.id)">{{ typeDish.times.lunch.type }}</label>
                    </div>
                    <div class="inputs-line pb-1" v-show="currentDate ==$store.state.cabinet.autoorder.available_dates[4].date">
                        <input type="checkbox" id="1111c" name="" value="8:00 - 8:30" />
                        <label for="1111c" @click="selectTypeDish(typeDish.times.breakfast.id)">{{ typeDish.times.breakfast.type }}</label>
                       
                        <input type="checkbox" id="3333b" name="" value="orange" />
                        <label for="3333b" @click="selectTypeDish(typeDish.times.dinner.id)">{{ typeDish.times.dinner.type }}</label>
                        <input type="checkbox" id="2221n" name="" value="8:00 - 10:59" />
                        <label for="2221n" @click="selectTypeDish(typeDish.times.lunch.id)">{{ typeDish.times.lunch.type }}</label>
                    </div>
                    <div class="inputs-line pb-1" v-show="currentDate ==$store.state.cabinet.autoorder.available_dates[5].date">
                        <input type="checkbox" id="1111cc" name="" value="8:00 - 8:30" />
                        <label for="1111cc" @click="selectTypeDish(typeDish.times.breakfast.id)">{{ typeDish.times.breakfast.type }}</label>
                      
                        <input type="checkbox" id="3333bb" name="" value="orange" />
                        <label for="3333bb" @click="selectTypeDish(typeDish.times.dinner.id)">{{ typeDish.times.dinner.type }}</label>
                        <input type="checkbox" id="2221nn" name="" value="8:00 - 10:59" />
                        <label for="2221nn" @click="selectTypeDish(typeDish.times.lunch.id)">{{ typeDish.times.lunch.type }}</label>
                    </div>
                    <div class="inputs-line pb-1" v-show="currentDate ==$store.state.cabinet.autoorder.available_dates[6].date">
                        <input type="checkbox" id="1111ccc" name="" value="8:00 - 8:30" />
                        <label for="1111ccc" @click="selectTypeDish(typeDish.times.breakfast.id)">{{ typeDish.times.breakfast.type }}</label>
                       
                        <input type="checkbox" id="3333bbb" name="" value="orange" />
                        <label for="3333bbb" @click="selectTypeDish(typeDish.times.dinner.id)">{{ typeDish.times.dinner.type }}</label>
                        <input type="checkbox" id="2221nnn" name="" value="8:00 - 10:59" />
                        <label for="2221nnn" @click="selectTypeDish(typeDish.times.lunch.id)">{{ typeDish.times.lunch.type }}</label>
                    </div>
                </div>
            </div>

            <div class="
            col-md-12
            mt-4
            mb-4 mb-md-5
            pb-3
            d-flex
            justify-content-center
            d-md-block
          ">
                <button class="btn-oval" @click="generateMenu">
                    Сформировать заказы
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import Navbar from '@/components/Navbar.vue'
// import Footer from '@/components/Footer.vue'
// import NavbarVertical from '@/components/NavbarVertical.vue'
export default {
    name: "Cart",
    components: {
        // Navbar,
        // Footer,
        // NavbarVertical,
    },
        head: {
        title: {
            inner: 'Автозаказ',
            separator: '-',
            complement: 'Ели Млели'
        },
    },
    data() {
        return {
            //date: new Date(),
            selectedDate: null,
            // dates: [{
            //     date: new Date(),
            // }],
            listDates: [],
            typeDish: {
                times: {
                    breakfast: {
                        type: null,
                    },
                    dinner: {
                        type: null,
                    },
                    lunch: {
                        type: null,
                    },
                },
            },
            currentDate: null,
            dates: {
                dates: [],
            },
            datesRaw:[]
        };
    },
    async mounted() {
        await this.$store.dispatch("actionGetAutoorder");
        const dates = this.$store.state.cabinet.autoorder.available_dates;
        console.log('adadadad',dates)
        this.datesRaw = dates.filter(function (e) {
       return e.status;
});
console.log('this.datesRaw',this.datesRaw);
    },
    methods: {
        allowedDates: function (date) {
            return this.datesRaw.some(
                (item) => JSON.stringify(item.date) == JSON.stringify(date)
            );
        },
        add() {
            this.dates.push({
                date: new Date(),
            });
            console.log(this.inputs);
        },
        selectDate(index, date) {
            // let au  = {
            //     "dates":[
            //         {"date":"2022-06-10","types":[1,2,3]},
            //         {"date":"2022-06-11","types":[1,2]}]
            //     }
            // let ab = {
            //     "dates": []
            // }
            //Класс выделеного

            //Сохраняем выделенную дату
            this.currentDate = date;
            //Сохраняем типы
            this.typeDish = this.$store.state.cabinet.autoorder.available_dates.find(item => item.date == date);
               // this.$store.state.cabinet.autoorder.available_dates[index];
            console.log('this.typeDish',this.typeDish)
           //this.$store.state.cabinet.autoorder.available_dates.

            //Toggle element of array
            const indexEl = this.listDates.findIndex((x) => x.date == date);
            //  const is_active = this.listDates[indexEl]['is_active']
            this.listDates.forEach((element) => {
                element.is_active = false;
                //alert(element.is_active)
            });
            this.listDates[indexEl]["is_active"] = true;
            const indexx = this.dates.dates.findIndex((x) => x.date == date);
            if (indexx === -1) {
                this.dates.dates.push({
                    date: this.typeDish.date,
                    types: [],
                });
            }
            //   else {
            //     this.dates.dates.splice(indexx, 1);
            //   }

            //   console.log(this.dates.dates)
            this.$forceUpdate();
        },
        selectTypeDish(id) {
            const index = this.dates.dates.findIndex(
                (x) => x.date == this.currentDate
            );
            const indexType = this.dates.dates[index]["types"].findIndex((x) => x == id)
            if (indexType === -1) {
                this.dates.dates[index]["types"].push(id);
            } else {
                this.dates.dates[index]["types"].splice(indexType, 1);
            }

            console.log(this.dates.dates);
        },
        async generateMenu() {
            //Проверяем выбраны ли все пункты
            var t = false;
            if (this.dates.dates.length != this.listDates.length || this.listDates.length==0) {
                t = true
            }

            this.dates.dates.forEach(element => {
                if (element.types.length == 0) {
                    t = true
                }
            });
            console.log('sssrwerwrwrs', this.dates)
              console.log('sssrwerwrwrs', t)
            if (t) {
                 this.$toast.info("Не выбраны типы блюд");
            } else {
                 try {
                    await this.$store.dispatch("actionAutogenerateMenu", this.dates);
                    this.$router.push('/cart')
                } catch (error) {

                    alert(error)
                }
              
            }

        },
        showConsoleMessage() {
            const index = this.listDates.findIndex(
                (x) => x.date == this.selectedDate
            );
            if (index === -1) {
                this.listDates.push({
                    is_active: false,
                    date: this.selectedDate
                });
            }

            console.log(this.listDates);
        },
    },
};
</script>

<style lang="scss" scoped>
.cabinet {
    display: flex;
    justify-content: space-between;
    margin-top: 85px;
    padding-bottom: 270px;

    @include media-breakpoint-down(md) {
        padding-bottom: 110px;
        margin-top: 25px;
        flex-direction: column;
    }

    &__nav {
        width: 250px;
    }

    &__content {
        width: calc(100% - 326px);
        background: #ffffff;
        box-shadow: -10px 6px 23px rgba(156, 156, 156, 0.1);
        border-radius: 12px;
        padding: 40px 10px 0px 49px;

        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            padding-top: 20px;
        }
    }

    &__header {
        margin-bottom: 29px;
        font-size: 24px;
    }
}

#app>div {
    background: url();
    background: #f5f5f5;
}

.footer {
    background: #fff;
}

.top-navbar {
    background: #fff !important;
}

.inputs-line {
    @include media-breakpoint-down(md) {
        display: flex;
        overflow: scroll;
    }

    input[type="checkbox"],
    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;

        @include media-breakpoint-down(md) {
            white-space: nowrap;
        }
    }

    label {
        display: inline-block;
        background-color: #fff;
        padding: 10px 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 109.5%;
        /* or 15px */

        color: #626262;

        border: 2px solid #f5f5f5;
        margin-bottom: 10px;
        border-radius: 12px;
        margin-right: 10px;
        text-align: center;
        // width: 160px;
        padding-left: 33px;
        padding-right: 33px;
        cursor: pointer;
        background: #f5f5f5;

        @include media-breakpoint-down(md) {
            white-space: nowrap;
        }
    }

    // label:hover {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    // input[type="checkbox"]:focus+label {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    input[type="checkbox"]:checked+label,
    input[type="radio"]:checked+label {
        background-color: #3f2a2c;
        border-color: #3f2a2c;
        color: #fff;
    }

    &--orange {

        input[type="checkbox"]:checked+label,
        input[type="radio"]:checked+label {
            background-color: $orange;
            border-color: $orange;
            color: #fff;
        }
    }
}

.autoOrder-inputs {
    display: flex;
    flex-wrap: wrap;

    &__day {
        width: 156px;
        height: 48px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        border: 1px solid #d3d3d3;
        background: url(../assets/calendar-orange.svg) 88% 50% no-repeat;
        border-radius: 9px;
        padding-left: 22px;

        &--orange {
            background: url(../assets/calendar-white.svg) 88% 50% no-repeat $orange;
            color: #fff;
        }
    }

    &__add-day {
        background: #fff;
        border: 1px dashed #d3d3d3;
        box-sizing: border-box;
        border-radius: 9px;
        width: 210px;
        height: 48px;
        text-align: left;
        padding-left: 22px;
        background: url(../assets/calendar-gray.svg) 88% 50% no-repeat;
    }
}

.autoOrderItem__header {
    font-size: 18px;
    line-height: 109.5%;
    margin-bottom: 15px;

    @include media-breakpoint-down(md) {
        text-align: center;
    }
}

.autoOrder-inputs-days {
    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
            margin-bottom: 15px;
        }
    }
}

.calendar {
    position: relative;
    background: url(../assets/calendar-orange.svg) 85% 50% no-repeat !important;
    width: fit-content;
    // margin-left: auto;
    // margin-right: auto;
    margin-right: 10px;
    margin-bottom: 10px;
}

.btnSelectDay {
    background: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 9px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    height: 48px;
    padding-right: 60px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding-left: 22px;
    color: #000000;
}

.btnSelectDay.active {
    background: #ccc;
}

.vd-activator {}

.vd-wrapper {
    display: block;
    margin-left: inherit;
    margin-right: inherit;

    div {
        display: block !important;
    }
}
</style>
